import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, Router } from '../../server/routes';
import { IReduxState } from '../../store/reducers';
import { IInitialPropsContext } from '../../typings/boilerplate';
import { DEBUG_AUTH } from '../../store/api';

const getDisplayName = (WrappedComponent: any) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

interface IProps {
  token?: string;
  r?: string;
  connected: boolean;
}

export default function(WrappedComponent: any) {
  class RequireGuest extends Component<IProps> {
    static displayName: string = `RequireGuest(${getDisplayName(WrappedComponent)})`;

    static async getInitialProps(ctx: IInitialPropsContext) {
      const storeState: IReduxState = ctx.store.getState();
      const { r } = ctx.query;
      const {
        auth: { connected }
      } = storeState;

      if (connected) {
        if (ctx.req) {
          DEBUG_AUTH && console.info('Redirecting to dashboard...');
          ctx.res.writeHead(302, { Location: r || ROUTES.DASHBOARD.url });
          ctx.res.end();
        } else {
          Router.pushRoute((r as string) || ROUTES.DASHBOARD.url);
        }
        return null;
      }

      const componentProps =
        WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
      return { ...componentProps, r };
    }

    componentWillMount(): void {
      this.handleAuthentication(this.props);
    }

    componentWillUpdate(nextProps: IProps): void {
      if (this.props.connected !== nextProps.connected) {
        this.handleAuthentication(nextProps);
      }
    }

    handleAuthentication(props: IProps): void {
      const { r } = props;
      if (props.connected) {
        Router.pushRoute((r as string) || ROUTES.DASHBOARD.url);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ auth }: IReduxState) => ({
    connected: auth.connected
  });

  return connect(mapStateToProps)(RequireGuest);
}
