import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import theme from '../theme/theme';

export const Container = styled(Grid)`
  height: 100vh;

  form {
    width: 100%;
    margin-top: 8px;
  }

  .MuiButton-root {
    margin: 24px 0 16px;
  }

  .MuiAvatar-root {
    margin: 8px;
    background-color: #f50057!important;
  }

  .success {
    color: ${theme.palette.custom.success};
  }
`;

export const GridLeft = styled(Grid)<{ backgroundImage: string; }>`
  background-image: url("${props => props.backgroundImage}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Wrapper = styled.div`
  margin: 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    width: 125px;
  }
`;
